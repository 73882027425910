.container {
	width: 100%;
	max-width: 25rem;
	position: fixed;
	bottom: 0;
	left: 50%;
	background-color: var(--dark-blue);
	display: flex;
	border-radius: 0.6rem 0.6rem 0 0;
	justify-content: space-between;
	height: 60%;
	min-height: 30rem;
	transition: all 0.4s ease-in-out;
	overflow-y: hidden;
	padding: 0 1rem 1rem 1rem;
	box-sizing: border-box;
	transform: translateY(calc(100% - var(--chat-bottom-bar-height))) translateX(-50%);
	> .wrapper > i {
		font-size: 2.5rem;
		width: 2.5rem;
		cursor: pointer;
		overflow: hidden;
		margin-top: 0.45rem;
	}
	> .wrapper:first-child > *:first-child {
		margin-right: auto;
	}
	> .wrapper:last-child > *:first-child {
		margin-left: auto;
	}
	> .wrapper:nth-child(2) {
		justify-content: center;
	}
}
.wrapper {
	flex: 1;
	display: flex;
}

.buttonContainer {
	font-size: 1rem;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	padding: 0 1rem;
	height: 3.4rem;
	cursor: pointer;
	p {
		font-size: 0.9rem;
	}
	i {
		font-size: 1.5rem;
		margin-bottom: -0.3rem;
	}
}

.container .closeModal {
	margin: auto;
	font-size: 4rem;
	width: 4rem;
	cursor: pointer;
}

.container {
	min-height: 35rem;
}

.full {
	width: 100%;
	transform: translateY(0) translateX(-50%);
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	.closeModal {
		margin: 0 auto;
	}
}
