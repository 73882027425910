.container {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	padding: 0 1rem;
	overflow: hidden;
}

.datePickerContainer{
	display: flex;
	width: 100%;
	justify-content: space-between;
	margin-top: 1rem;
}

.searchResult{
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: center;
	overflow-y: auto;
	height: 100%;
	margin-bottom: 0.5rem;
}

.resultBlock{
	display: flex;
	align-items: center;
	width: 100%;
	margin-top: 0.6rem;

}

.resultBlockTexts{
	flex-direction: column;
}

.resultText{
	display: flow;
	font-size: 0.7em;
}

.resultTitle{
	font-size: 0.7em;
	font-weight: bold;
}

.pdfLogo{
	width: 2rem;
}

.downloadIcon{
	align-self: center;
	margin-left: auto;
	cursor: pointer;
	font-size: 2rem;
}

.dropdown{
	margin-top: 1rem;
}

.searchButton{
	margin-top: 1rem;
	background-color: #1ad3d1;
}

mark{
	color: yellow;
	background-color: transparent;
}