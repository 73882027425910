.label {
	align-self: flex-start;
	display: flex;
	align-items: center;
	padding-bottom: 0.2rem;
	font-size: 0.9rem;
	i {
		margin-left: auto;
	}
}
