.container {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0 1rem;
	
}

.roundContainer{
	width: 100%;
	height: 100%;
	display: flex;
	background-color: #ffffff44;
	flex-direction: column;
	//border-radius: 0.6rem;
	border-top-right-radius: 1rem;
	border-top-left-radius: 1rem;
	margin-top: 1rem;
	overflow-y: auto;
	overflow-x: hidden;
}

.scrollableContainer{
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.title{
	font-size: 2rem;
	font-weight: bold;
	text-transform: uppercase;
	margin-top: 2rem;
	color: black;
	text-align: center;
}