.container {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
	padding: 0 1rem;
	overflow: hidden;
}

.title{
	font-size: 2rem;
	font-weight: bold;
	text-transform: uppercase;
	margin-top: 2rem;
	color: black;
}

.roundContainer{
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #ffffff44;
	flex-direction: column;
	//border-radius: 0.6rem;
	border-top-right-radius: 1rem;
	border-top-left-radius: 1rem;
	margin-top: 1rem;
	overflow-y: auto;
	overflow-x: hidden;
}

.scrollableContainer{
	width:100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.innerContainer{
	width:80%;
	margin-top: 1rem;
}

.bottomLine {
	margin: 0 auto 1rem auto;
	margin-bottom: 2rem;
}

.uploadContainer{
	width: 100%;
	display: flex;
	flex-direction: column;
	color: black;
	margin-top: 1rem;
}

.uploadSelector{
	padding-left: 0.5rem;
}

.uploadButtonContainer{
	display: flex;
	justify-content: flex-end;
	margin: 1rem 0rem;
}

.createButton{
	margin-top: auto;
	margin-bottom: 1rem;
}

.meetingType{
	margin-top: 1rem;
}