.container{
    width: 100%;
	color: black;
}

div.optionsContainer{
    display: flex;
    justify-content: space-around;
    flex-direction: row;
}

.formContainer{
    width: min-content;
}