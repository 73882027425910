.container {
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 1.4rem;
	width: 100%;
	:global(.MuiCircularProgress-colorPrimary) {
		color: white;
	}
}
