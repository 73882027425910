.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	min-height: 100%;
	overflow: hidden;
}

.hearnokLogo {
	width: 70%;
	max-width: 20rem;
	margin: 2rem auto 2rem auto;
}

.roundContainer{
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	background-color: #ffffff44;
	flex-direction: column;
	//border-radius: 0.6rem;
	border-top-right-radius: 0.6rem;
	border-top-left-radius: 0.6rem;
	margin-top: 1rem;
	align-items: center;
	overflow-y: auto;
	overflow-x: hidden;
}

.scrollableContainer{
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.titleContainer{
	display: flex;
	flex-direction: column;
	margin-top: 2rem;
	margin-bottom: 1rem;
	align-items: center;
	text-align: center;
	width: 70%;
}

.titleText{
	font-weight: bold;
	font-size: 2rem;
	color: black;
	text-transform: uppercase;
}

.titleInfo{
	font-size: 1rem;
	color: black;
	margin-top: 1rem;
}

.passwordContainer{
	width: 80%;
	margin-top: auto;
	margin-bottom: 1rem;

}

.bottomLine {
	margin: auto auto 1rem auto;
	color: black;
}
