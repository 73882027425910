.container {
	overflow: hidden;
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
}
.tableContainer{
	--padding-top: 2rem;
	height: calc(100% - var(--chat-bottom-bar-height));
	width: 100%;
}

.info {
	height: fit-content;
	width: fit-content;
	margin: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	p {
		text-align: center;
		margin-bottom: 2rem;
	}
}

.label {
	display: inline;
	margin-right: 0.6rem;
}
