.slideContainer {
	display: flex;
	width: 200%;
	height: 100%;
	align-self: flex-start;
	transition: all 0.3s ease-in-out;
}

.wrapper {
	width: 100%;
	height: 100%;
}
