@import url("https://fonts.googleapis.com/css?family=Montserrat");

html,
body,
#root {
	min-height: 100%;
	height: 100%;
	overflow: hidden;
}

:root {
    --base-font-size: 16px;
}

html,
body {
	font-size: var(--base-font-size);
}

#root {
	font-size: var(--base-font-size);
	color: white;
}

* {
	margin: 0;
	padding: 0;
	font-family: "Montserrat";
}

p {
	display: block;
	margin-block-start: 0;
	margin-block-end: 0;
	margin-inline-start: 0rem;
	margin-inline-end: 0rem;
}

body i {
	user-select: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-box-shadow: 0 0 0 2rem white /*var(--opaque-blue)*/ inset !important;
}
input:-webkit-autofill {
	-webkit-text-fill-color: black !important;
}

h4 {
	font-size: 1rem;
	text-transform: uppercase;
	font-weight: normal;
}

h5 {
	font-size: 1rem;
	font-weight: bold;
	cursor: pointer;
}

.errorText {
	color: var(--red);
}

.centered {
	justify-self: auto;
	align-self: auto;
	margin: 0 auto;
}

.MuiPaper-root {
	background-color: white /*var(--dark-blue)*/ !important;
	color: black !important;
}

.MuiInput-underline:hover:not(.Mui-disabled):before {
	border: none !important;
}

.MuiInput-underline:after {
	border-bottom: none !important;
}

.MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected:hover {
	background-color: rgba(255, 255, 255, 0.08) !important;
}

/* DatePicker CSS */
/* Picker background color*/
.MuiPickersLayout-root.css-1g7nc1s-MuiPickersLayout-root{
	background-color: #FFFFFF;
	border: gray 0.125rem solid;
	border-radius:  0.6rem;
}

div.MuiPickersPopper-paper {
	border-radius:  0.6rem;
}

.MuiPickersCalendarHeader-labelContainer{
	color: black;
}

/*Scrollbar*/
/* width */
::-webkit-scrollbar {
	width: 0.6rem;
}
/* Track */
::-webkit-scrollbar-track {
	background: #f1f1f1aa;
	border-radius: 0.3rem;
}
/* Handle */
::-webkit-scrollbar-thumb {
	background: #888;
	border-radius: 0.3rem;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555;
}

/*Rating stars*/
.MuiRating-root.MuiRating-sizeLarge {
    margin-top: 1rem;
}

/*Rating popup fextfield*/
.MuiFormControl-root.MuiTextField-root{
	margin-top: 1rem;
	width: 80%;
}
