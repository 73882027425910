.container {
	background-color: white /*var(--light-blue)*/;
	min-width: 7rem;
	height: min-content;
	width: max-content;
	padding:  0.6rem 0.8rem;
	color: #1ad3d1;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 1rem;
	cursor: pointer;
	transition: color 0.2s ease-in-out;
	position: relative;
	overflow: hidden;
	&:hover {
		color: #0f7977;
	}
}

.disabled {
	background-color: #555555;
	&::after {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: black;
		z-index: 1;
		content: " ";
		opacity: 0;
	}
	&:hover {
		background-color: var(--light-blue);
	}
}

.iconContainer{
	display: flex;
	align-items: center;
	width: 100%;
}

.icon{
	font-size: 1.4rem;
	margin-right: 0.6rem;
}

.text {
    color: #1ad3d1;
    font-size: 1rem;
    white-space: nowrap;
}