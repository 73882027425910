.container {
	width: 100%;
	position: relative;
	transition: height 0.5s ease-in-out;
}

.buttonContainer{
    background-color: white;
	width: 100%;
	height: 3rem;
	color: white;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-radius: 1rem;
	cursor: pointer;
	transition: background-color 0.2s ease-in-out;
	overflow: hidden;
	&:hover {
		background-color: var(--opaque-blue);
	}
	position: absolute;
}

.dropdownContainer {
	display: flex;
	width: 100%;
	align-self: flex-start;
	position: relative;
    background-color: #FFFFFF33;
	transition: height 0.5s ease-in-out;
	overflow-y: hidden;
	translate: 0rem 1.5rem;
	float: left;
	border-bottom-right-radius: 1rem;
	border-bottom-left-radius: 1rem;
}

.label{
    color: black;
	margin-left: 1rem;
	font-weight: bold;
}

.iconStyle{
    color: black;
	margin-right: 1rem;
}

.childElements{
	margin: 2rem 1rem 0rem 1rem;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}


