.container {
	width: 100%;
	height: calc(100% - var(--top-bar-height));
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
	padding: 0 1rem;
	margin-top: var(--top-bar-height);
	
}

.logoContainer{
	width: 100%;
	display: flex;
	justify-content: space-around;
	margin-top: 1rem;
}

.hearnokLogo{
	width: 40%;
	margin-top: auto;
	margin-bottom: auto;
}

.bniLogoContainer{
	width: 30%;
	align-items: center;
}

.bniLogo{
	width: 100%;
}

.bniText{
	text-align: center;
	color: gray;
	margin-bottom: 0.5rem;
}


.buttonContainer{
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: space-around;
	flex-direction: column;
	align-items: center;
	margin-bottom: 1rem;
}

.roundContainer{
	width: 100%;
	height: 100%;
	display: flex;
	//justify-content: space-around;
	background-color: #ffffff44;
	flex-direction: column;
	border-top-right-radius: 0.6rem;
	border-top-left-radius: 0.6rem;
	margin-top: 1rem;
	overflow-y: auto;
}

.connectButton {
	margin-top: 1rem;
	width: 80%;
	background-color: #ffc34b;
}

.createButton {
	margin-top: 1rem;
	width: 80%;
	background-color: #ff0a37;
}

.searchButton {
	margin-top: 1rem;
	width: 80%;
	background-color: #ff1f06;
}

.settingsButton {
	margin-top: 1rem;
	width: 80%;
	background-color: #ff5d27;
}

.welcomeContainer{
	display: flex;
	flex-direction: column;
	margin-top: 2rem;
	align-items: center;
	text-align: center;
}

.welcomeText{
	font-weight: bold;
	font-size: 2rem;
	color: black;
}

.welcomeInfoContainer{
	display: flex;
	flex-direction: row;
	margin-top: 1rem;
}

.welcomeInfo{
	font-size: 1rem;
	color: black;
}
