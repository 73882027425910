.container {
	//background-color: var(--mid-blue);
	background-image: linear-gradient(#fffecf, #ffabbb, #f68726 );
	height: 100%;
	width: 100%;
	display: flex;
	overflow: hidden;
	overflow-y: auto;
	box-sizing: border-box;
}

.innerDesktopContainer {
	display: flex;
	margin-left: auto;
	margin-right: auto;
	width: 25rem;
	height: 100%;
	overflow: hidden;
	position: relative;
	box-sizing: border-box;
}
