.messagesContainer {
	--padding-top: 2rem;
	display: flex;
	height: calc(100% - var(--chat-bottom-bar-height) - var(--padding-top));
	width: 100%;
	flex-direction: column-reverse;
	overflow-y: auto;
	//padding-right: 2.5rem;
	padding-top: var(--padding-top);
}

.messagesContainer > * {
	margin-bottom: 0.2rem;
}

.messagesContainer > *:last-child {
	margin-bottom: 0;
}

.messageBlock {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
}

.msg {
	display: flex;
	flex-direction: column;
	font-size: 0.9rem;
	align-items: center;
	flex-shrink: 0;
	justify-content: center;
	width: max-content;
	max-width: 80%;
	word-break: break-word;
	border-radius: 1rem;
	padding: 0.3rem 1rem 0.3rem 1rem;
	background-color: var(--opaque-blue);
	line-height: 1.2rem;
}

.ownMsg {
	margin-left: auto;
}

.ownName {
	text-align: end;
}

.circle {
	margin-top: 0.2rem;
	width: 1.5rem;
	height: 1.5rem;
	border-radius: 0.75rem;
	margin-right: 0.5rem;
}

.systemMsg {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 1rem auto;
	> p:first-child {
		font-size: 0.7rem;
	}
	> p:last-child {
		font-size: 0.9rem;
	}
}

.name {
	font-size: 0.8rem;
	margin: 0.75rem 0 0.4rem 0;
}

.originalText{
	font-size: 0.9rem;
}

.translatedText{
	font-size: 0.9rem;
	color: black;
}

