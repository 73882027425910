.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
	p {
		margin-top: auto;
		text-align: center;
	}
	> div {
		margin-top: auto;
		margin-bottom: 2rem;
	}
}

.loaderContainer {
	height: 5rem;
	width: 5rem;
	align-self: center;
	margin: 0 auto 0 auto;
}
