.container {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.65);
	backdrop-filter: blur(0.4rem);
}

.popupContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	background: white;
	border-radius: 0.6rem;
	width: 80%;
	min-height: 12rem;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translateY(-50%) translateX(-50%);
	overflow: hidden;
	color: white;
	align-items: center;
	> *:first-child {
		height: 100%;
		margin: auto;
	}
	i {
		position: absolute;
		top: 0.5rem;
		right: 0.5rem;
		cursor: pointer;
	}
}

@media screen and (min-width: 601px) {
	.popupContainer {
		width: 370px;
	}
}

@media screen and (min-width: 1921px) {
	.popupContainer {
		width: 740px;
	}
}

.title {
	width: 100%;
	height: 2rem;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 1.1rem;
	font-weight: bold;
}

.button {
	color: white;
	background-color: #1ad3d1;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	padding: 1rem 2rem;
	margin-bottom: 1rem;
}

.content {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 1rem 1rem;
	width: 100%;
	box-sizing: border-box;
}

.icon{
	font-size: 1rem;
	color: #1ad3d1;
	cursor: pointer;
	position: absolute;
	top: 1rem;
	right: 1rem;
}
