.container {
	background-color: #1ad3d1 /*var(--light-blue)*/;
	min-width: 7rem;
	height: min-content;
	min-height: 1.2rem;
	box-shadow: 0rem 0.125rem 0.125rem gray;
	width: max-content;
	padding:  0.6rem 0.8rem;
	color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 1rem;
	cursor: pointer;
	transition: background-color 0.2s ease-in-out;
	position: relative;
	overflow: hidden;
	&:hover {
		background-color: var(--opaque-blue);
	}
}

.disabled {
	background-color: #555555;
	&::after {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: black;
		z-index: 1;
		content: " ";
		opacity: 0;
	}
	&:hover {
		background-color: var(--light-blue);
	}
}

.loaderCont {
	height: 100%;
	width: 100%;
}

.iconContainer{
	display: flex;
	align-items: center;
	width: 100%;
}

.iconStyle{
	font-size: 4rem;
	margin-right: 0.6rem;
}