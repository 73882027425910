.chatMemberContainer {
	display: flex;
	margin-bottom: 1rem;
	align-items: center;
}

.circle {
	width: 2rem;
	height: 2rem;
	border-radius: 1rem;
	margin-right: 1rem;
}

.title {
	font-size: 1.1rem;
	font-weight: bold;
	margin-top: 1rem;
	margin-bottom: 0.5rem;
}

.titleDiv {
	font-size: 1.1rem;
	font-weight: bold;
	margin-top: 1rem;
	margin-bottom: 0.5rem;
	display: flex;
	justify-content: space-between;
}

.export {
	text-decoration: underline;
	margin-top: 1rem;
}

.name {
	font-size: 0.8rem;
	margin: 0.75rem 0 0.4rem 0;
}

.userBlock{
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
}
.userBlockName{
	display: flex;
	align-items: flex-start;
}

.button {
	font-size: 0.8rem;
}

.wrapper {
	display: flex;
	align-items: flex-end;
}

.micIcon {
	margin: auto;
	font-size: 2rem;
	width: 2rem;
	cursor: pointer;
}

.userList {
	width: 100%;
	height: 10rem;
	overflow-y: scroll;
	min-height: 10rem;
	overflow-x: hidden;
}