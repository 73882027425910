body {
	/*******Colors**************/
	--mid-blue: #20395c;
	--light-blue: #0276bd;
	--dark-blue: #041b31;
	--opaque-blue: #364c6c;
	--yellow: #fecc00;
	--red: #ef5350;
	--green: #23b820;

	--input-padding: 0.2rem 0.5rem 0.2rem 0.5rem;
	--top-bar-height: 2.5rem;
	--chat-bottom-bar-height: 3.4rem;
}
