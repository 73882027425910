.container {
	position: absolute;
	top: 1rem;
	right: 1rem;
	display: flex;
	flex-direction: column;
	cursor: pointer;
	img {
		width: 2rem;
		height: auto;
	}
	> img:first-child {
		margin-bottom: 0.4rem;
	}
}

.otherFlags{
	display: flex;
	flex-direction: column;

	img {
		margin-bottom: 0.4rem;
	}
}
