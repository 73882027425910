.container {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow: hidden;
	padding: 0 1rem;
}

.title{
	font-size: 2rem;
	font-weight: bold;
	text-transform: uppercase;
	margin-top: 2rem;
	color: black;
}

.roundContainer{
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: space-around;
	background-color: #ffffff44;
	flex-direction: column;
	//border-radius: 0.6rem;
	border-top-right-radius: 1rem;
	border-top-left-radius: 1rem;
	margin-top: 1rem;
	overflow-y: auto;
	overflow-x: hidden;
}

.scrollableContainer{
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.innerContainer{
	width:80%;
	margin-top: 1rem;
	margin-bottom: 1rem;

}