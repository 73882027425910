.container {
	width: 90%;
	max-width: 25rem;
	padding: 1rem 2rem;
	position: fixed;
	top: 3rem;
	left: 50%;
	transform: translateX(-50%);
	z-index: 10000;
	background-color: #1ad3d1;
	border-radius: 0.6rem;
	opacity: 0.95;
	color: white;
	transition: opacity 0.4s ease-in-out;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
	h5 {
		margin-right: 0.5rem;
		cursor: default;
		letter-spacing: 0.0625rem;
		text-align: center;
	}
	i {
		font-size: 1.5rem;
		line-height: 1.2rem;
	}
}

.titleContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 auto 0 auto;
}

.green {
	color: white;
}

.error {
	color: #ff0a37;
}

.text {
	margin-top: 1rem;
	font-size: 0.9rem;
	width: 100%;
}

.disappearing {
	opacity: 0;
}

.close {
	position: absolute;
	top: 0.3rem;
	right: 0.3rem;
	font-size: 1.2rem;
	cursor: pointer;
}
