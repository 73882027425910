.container {
	display: flex;
	flex-direction: column;
	width: 50%;
	height: 100%;
	align-items: center;
	justify-content: center;
	overflow-y: auto;
	overflow-x: hidden;
}

.scrollableContainer{
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.titleContainer{
	display: flex;
	flex-direction: column;
	margin-top: 2rem;
	margin-bottom: 1rem;
	align-items: center;
	text-align: center;
	width: 70%;
}

.titleText{
	font-weight: bold;
	font-size: 2rem;
	color: black;
	text-transform: uppercase;
}

.titleInfo{
	font-size: 1rem;
	color: black;
	margin-top: 1rem;
}

.fullSliderContainer{
	margin-top: auto;
	margin-bottom: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.slideContainer {
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;
}

.fieldContainer {
	display: flex;
	flex-direction: column;
	width: 80%;
	margin-top: 1rem;
	margin-bottom: 1rem;
	> div {
		margin-bottom: 0rem;
	}
}

.pageIndexContainer{
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 80%;
}

.indexDot{
	background-color: gray;
	width: 0.8rem;
	height: 0.8rem;
	border-radius: 0.4rem;
	transition: background-color 0.3s ease-in-out;
	transition: width 0.3s ease-in-out;
}

.buttonContainer{
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.button{
	margin-left: 1rem;
	margin-right: 1rem;
}

.bottomLine {
	margin-top: 2rem;
	margin-bottom: 1rem;
	color: black;
}

.successText {
	color: black;
	margin-bottom: auto;
	margin-top: auto;
	text-align: center;
}
