.container {
	display: flex;
	flex-direction: column;
	width: 50%;
	height: 100%;
	align-items: center;
	justify-content: center;
	overflow-y: auto;
	overflow-x: hidden;
}

.scrollableContainer{
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.welcomeContainer{
	display: flex;
	flex-direction: column;
	height: min-content;
	margin-top: 2rem;
	margin-bottom: 1rem;
	align-items: center;
	text-align: center;
}

.forgotPass {
	margin-left: auto;
	color: #1ad3d1;
	text-decoration: underline;
}

.fieldsContainer {
	display: flex;
	flex-direction: column;
	width: min(80%, 20rem);
	margin-top: auto;
	
}

.input {
	margin-bottom: 1.5rem;
}

.button {
	margin-top: 2rem;
	margin-bottom: 1rem;
}

.noAcc {
	margin-bottom: 1rem;
	margin-top: auto;
	color: black;
}

.welcomeText{
	font-weight: bold;
	font-size: 2rem;
	color: black;
}

.welcomeInfo{
	font-size: 1rem;
	color: black;
}
