.container {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	padding: 1rem;
	align-items: center;
	background-color: white;
}

.errorMsg {
	color: black;
	font-weight: bold;
	margin-top: 0.5rem;
}

.errorText {
	margin-top: 1rem;
	text-align: center;
	color: black;
	font-size: 0.9rem;
}

svg.icon {
	font-size: 3rem;
	color: #1ad3d1;
	margin-top: 1rem;
}
