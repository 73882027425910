.container {
	width: 50%;
	padding: 0 2rem;
	box-sizing: border-box;
	margin-top: 1rem;
}

.deleteButton {
	background-color: var(--red);
}

.button {
	margin: 0 auto;
	margin-top: 3rem;
}

.infoText{
    color: black;
    margin-bottom: 1rem;
    margin-top: 1rem;
}