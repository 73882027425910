.container {
	width: 50%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.slideContainer{
	width: 80%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	color: black;
}

.inputfield{
	margin-top: 1rem;
}

.button {
	margin: 1rem auto 1rem auto;
}

.textButton{
	margin-top: 1rem;
}

.errorText {
	margin-top: 1rem;
	color: var(--red);
}
