.container {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: space-around;
}

.data {
	display: flex;
	flex-direction: column;
	width: fit-content;
	div {
		display: flex;
		justify-content: space-between;
	}
	> div:last-child {
		margin-top: 1rem;
		> p {
			font-size: 0.9rem;
		}
	}
}

.codeContainer {
	display: flex;
	margin: 0 auto;
	align-items: center;
	h5 {
		margin-left: 2rem;
		font-size: 1.2rem;
	}
}

.flex {
	display: flex;
	align-items: center;
	h5 {
		margin-right: 1rem;
		font-size: 0.9rem;
	}
	i {
		cursor: pointer;
		font-size: 1.5rem;
		&:hover {
			opacity: 0.8;
		}
	}
}
