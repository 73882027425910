.container {
	width: 100%;
	color: black;
}

.container :global(.MuiInputBase-root) {
	background-color: white /*var(--opaque-blue)*/ !important;
	border-radius: 0.6rem;
	border: gray /*var(--opaque-blue)*/ 0.125rem solid;
	overflow: hidden;
	width: 100%;
	height: 2.5rem;
	outline: none;
	color: black;
	padding: var(--input-padding);
	&::before {
		border: none;
		&:hover {
			border: none;
		}
	}
	&:hover {
		//border-radius: 0.6rem;
		border: black 0.125rem solid;
	}
	:global(.MuiInput-underline:after) {
		border-bottom: none !important;
	}
	:global(.MuiButtonBase-root) {
		background-color: black !important;
	}
	:global(.MuiSelect-root) {
		background-color: white !important;
	}
	> fieldset {
		//border-radius: 0.6rem;
		border: none;
	}
}
