.container {
	position: relative;
	> :global(.MuiLinearProgress-root) {
		height: 1rem;
		width: 100%;
	}
	height: 3rem;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
}

.setter {
	left: 0;
	width: 0.3rem;
	height: 2rem;
	background: white;
	position: absolute;
	z-index: 1;
	cursor: pointer;
}

.slider {
	position: absolute;
	top: 0.5rem;
	left: 0;
	width: 100%;
}

:global(.MuiSlider-rail),
:global(.MuiSlider-track) {
	opacity: 0 !important;
}

:global(.MuiSlider-thumb) {
	height: 2rem !important;
	border-radius: 0 !important;
	margin: 0 !important;
	top: 0 !important;
	background-color: white !important;
}
