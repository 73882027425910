.container {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: min-content;
	color: black;
}

.inputContainer {
	background-color: white/*var(--opaque-blue)*/ !important;
	border-radius: 0.6rem;
	width: 100%;
	height: 2.5rem;
	overflow: hidden;
	padding: var(--input-padding);
	box-sizing: border-box;
	display: flex;
	align-items: center;
	border: gray /*var(--opaque-blue)*/ 0.125rem solid;
	transition: border-color 0.2s ease-in-out;
	input {
		height: 100%;
		width: 100%;
		background-color: white/*var(--opaque-blue)*/ !important;
		border: none;
		outline: none;
		font-size: 1rem;
		color: black;
	}
	i {
		font-size: 1.3rem;
		padding-right: 0.2rem;
	}
}

.errorInput {
	border-color: var(--red);
}

.error {
	display: flex;
	align-items: center;
	min-height: 0.8rem;
	margin-top: 0.4rem;
	font-size: 0.9rem;
	color: var(--red);
	font-weight: bold;
	i {
		font-size: 1.2rem;
		margin-right: 0.5rem;
	}
}
