.container {
	display: flex;
	align-items: center;
	justify-content: center;
	strong {
		text-decoration: underline;
	}
}

.box {
	background-color: white;
	color: white;
	height: 1.2rem;
	width: 1.2rem;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 1rem;
	border-radius: 0.2rem;
	border: gray 0.125rem solid;
	i {
		font-size: 1.2rem;
	}
}

.checked {
	background-color: #1ad3d1 /*var(--light-blue)*/;
}
