.container {
	position: absolute;
	width: 100%;
	height: var(--top-bar-height);
	background-color: var(--dark-blue);
	top: 0;
	left: 0;
	display: flex;
	padding: 0 0.5rem;
	box-sizing: border-box;
	justify-content: space-between;
	align-items: center;
	i {
		font-size: 2rem;
		cursor: pointer;
	}
}
