.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	min-height: 100%;
	overflow: hidden;
}

.roundContainer{
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: space-around;
	background-color: #ffffff44;
	flex-direction: column;
	//border-radius: 0.6rem;
	border-top-right-radius: 0.6rem;
	border-top-left-radius: 0.6rem;
	margin-top: 1rem;
	overflow: hidden;
}

.hearnokLogo {
	width: 70%;
	max-width: 20rem;
	margin: 2rem auto 2rem auto;
}

.logo path {
	fill: white;
}

.slideContainer {
	display: flex;
	width: 200%;
	height: 100%;
	align-self: flex-start;
	transition: all 0.3s ease-in-out;
}
