.container {
	height: 100%;
	width: 50%;
	display: flex;
	flex-direction: column;
	align-items: center;
	color: black;
	overflow-y: auto;
	overflow-x: hidden;
}

.scrollableContainer{
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.innerContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: min(80%, 20rem);
	margin-top: auto;
	> p {
		font-size: 0.9rem;
		margin-bottom: 2rem;
		text-align: center;
	}
}

.title{
	font-size: 2rem;
	font-weight: bold;
	text-transform: uppercase;
	margin-top: 2rem;
}

.button {
	margin-top: 2rem;
	margin-bottom: 1rem;
}

.noAcc {
	margin-top: auto;
	margin-bottom: 1rem;
}
